<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">在线卡管理</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button>
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="rechargeBack">充值</el-button>
            </div>
        </div>
        <el-card class="el-main">

            <div class="a-flex-rfsc">
                <le-input label="卡号" v-model="pageParam.params.cardNo" placeholder="请输入卡号" />
                <le-input label="手机号" v-model="pageParam.params.phone" placeholder="请输入手机号" />
                <el-button type="primary" class="s-btn-add" style="margin-left: 10px" @click="onSearch">搜索</el-button>
            </div>

            <le-pagview @setData="setTableData" :pageParam="pageParam" v-if="pageParam" :isFixed="true" :tableRef="$refs.chargeCardOnList">
                <el-table ref="chargeCardOnList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="cardNo" label="卡号">
                    </el-table-column>
                    <el-table-column label="当前余额">
                        <template slot-scope="{ row }">
                            <span v-if="row.cardBalance < 0">-&yen;{{ util.numFormat(Math.abs(row.cardBalance))
                            }}</span>
                            <span v-else>&yen;{{ util.numFormat(row.cardBalance) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="默认充电时长">
                        <template slot-scope="{ row }">
                            <span>{{ (Number(row.maxChargingMinutes) / 60).toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="充电次数">
                        <template slot-scope="{ row }">
                            <span>{{ row.chargingNum }}次</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        :key="3333"
                        current-row-key="33333"
                        column-key="nfcUseBalance"
                        label="实体卡使用账户余额和权限">
						<template slot-scope="scope">
                            <el-switch
                                @change="changeNfcUseBalance(scope.row,scope.$index)"
                                v-model="scope.row.nfcUseBalance"
                                :inactive-value="0"
                                :active-value="1"
                                active-color="#409EFF"
                                inactive-color="#F56C6C">
                            </el-switch>
                            <span class="a-mlr-12" :class="scope.row.nfcUseBalance?'a-c-blue':'a-c-error'">{{ scope.row.nfcUseBalance?'已启用':'已禁用' }}</span>
						</template>
					</el-table-column>
                    <el-table-column label="用户名/手机号">
                        <template slot-scope="{ row }">
                            <span class="a-c-blue a-cursor-p" @click="goUserDetail(row.userId)">
                                {{ row.nickName || '无' }}/{{ row.userMobile || '无' }}</span>
                        </template>
                    </el-table-column>
                    
                    <el-table-column label="操作" width="70" fixed="right">
                        <template slot-scope="{ row }">
                            <el-tooltip class="item" effect="dark" content="解绑" placement="top">
                                <div @click="handlerUnbindCard(row.userCardId)">

                                    <img src="../../assets/icon/userMGT-unbind.png" class="a-wh-16" />
                                </div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>

        </el-card>
        <!-- <le-upload-file-img v-show="false" :uploadUrl="$Config.apiUrl.batchImportDev" :acceptArr="['.xls', '.xlsx']"
            notnull ref="pic" label="" @handleSuccess="importDevSuccess" v-model="deviceXLS"></le-upload-file-img> -->
        <le-upload-file-img 
            v-show="false" 
            :uploadUrl="$Config.apiUrl.importUserCard" 
            :acceptArr="['.xls','.xlsx']" 
            notnull 
            ref="recharge" 
            :limit="999"
            label="" 
            @handleSuccess="importExcSuccess"
            v-model="rechargeXLS"></le-upload-file-img>
        <export-result-list ref="exportResult" :tableData="exportData"></export-result-list>
    </div>
</template>
<script>
import util from '../../utils/util';
export default {
    data() {
        return {
            util: util,
            tableData: [],
            pageParam: null,
            fileUrl: '',
            rechargeXLS: '',
            exportData: []
        }
    },

    methods: {
        onSearch () {
            this.pageParam.freshCtrl++; // 重新请求表格数据
        },
        exportfile() {
            if (!this.fileUrl) {
                this.$message.warning('暂无可导出文件')
                return
            }
            this.$confirm('是否确认导出文件？', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(_ => {
                this.$Utils.downloadUrlFile(this.fileUrl, '在线充电卡列表')
            }).catch(_ => { })
        },
        setTableData(data, fileUrl) {
            this.tableData = data.map(item=>{
                return {
                    ...item,
                    nfcUseBalance: item.nfcUseBalance ? 1 : 0
                }
            });
            console.log(this.tableData);
            this.fileUrl = fileUrl

        },
        rechargeBack () {
            this.$refs['recharge'].$refs["upload"].$refs["upload-inner"].handleClick();
        },
        importExcSuccess(data) {
            this.$message.success('导入成功')
            this.exportData = data
            if(data && data.length){
                this.$refs['exportResult'].dialogVisible = true
            }
            this.pageParam.freshCtrl++; // 重新请求表格数据
        },
        goUserDetail(id) {
            if (!id) {
                this.$message.error('此卡没有关联用户！')
                return false
            }
            this.$router.push({
                path: '/userMGT/userMGT-detail',
                query: { id: id }
            })
        },
        handlerUnbindCard(id) {
            this.$confirm('解绑实体卡后，将会同步解除该卡片与套餐的绑定关系。是否确认解绑该卡？', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(_ => {
                this.$Axios._post({
                    url: this.$Config.apiUrl.unbindUserCard,
                    params: {
                        "userCardId": id,
                    }
                }).then((res) => {
                    if (res.result.code == 0) {
                        this.$notify({
                            title: '操作成功',
                            type: 'success'
                        })
                        this.pageParam.freshCtrl++;
                    } else {
                        this.$message.error(res.result.message)
                    }

                }).catch((error) => {
                    this.$message.error(error);
                })
            }).catch(err => { console.log(err); })
        },
        changeNfcUseBalance (datas, index) {
            let txt
            if(datas.nfcUseBalance == 1){
                txt = '是否确定启用该卡片使用账户余额和权限？'
            }else{
                txt = '是否确定禁用该卡片使用账户余额和权限？'
            }
            this.$confirm(txt, '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(_=>{
                this.$Axios._post({
                    url: this.$Config.apiUrl.updateUserCard,
                    params: {
                        id: datas.userCardId,
                        nfcUseBalance: datas.nfcUseBalance
                    }
                }).then((res) => {
                    this.$message.success('操作成功')
                    this.pageParam.freshCtrl++;
                }).catch((error) => {
                    this.$message.error(error);
                })
            }).catch(_=>{
                this.tableData[index].nfcUseBalance = this.tableData[index].nfcUseBalance == 0 ? 1 : 0
            })
            
        },
    },
    created() {
        this.pageParam = {
            url: this.$Config.apiUrl.onLineCardList,
            method: "post",
            params: {
                exportFlag: true,
                cardNo: '',
                startTime: '',
                endTime: '',
                phone: '',
            },
            freshCtrl: 1,
        };
    },
}
</script>
<style lang="scss" scoped>
/deep/ .s-search-label {
}

.addDialog /deep/ .el-dialog__header {
    font-size: 16px;
    color: #303133;
    font-weight: bold;
    padding: 16px 24px;

}

.addDialog /deep/ .el-dialog__body {
    border-top: 1px solid #EBF0F5;
    border-bottom: 1px solid #EBF0F5;
    height: 136px;
    padding-left: 24px;
    padding-right: 24px;
}

.addDialog /deep/ .el-dialog__footer {
    padding-left: 24px;
    padding-right: 24px;
}

.addDialog /deep/ .el-form-item__label {
    text-align: left;

}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>